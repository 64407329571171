import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Container } from "react-bootstrap";
import { useLocation } from "@reach/router"
import { Seo } from "../components/Seo";

export default function ({ data:{legalTerms:{bannerTitle, termsContent:json}} }) {
    const cnt =json;
    // console.log("TESTTTTT");
    // console.log(json);
    const pathLocation = useLocation();
    const pageUrl = pathLocation.href;
    
    const options={
        renderNode:{

        [BLOCKS.HEADING_1]:(node, children) =>
        (<h1 className="heading1">{children}</h1>),

        [BLOCKS.HEADING_2]:(node, children) =>
        (<h2 className="heading2">{children}</h2>),

        [BLOCKS.HEADING_3]:(node, children) =>
        (<h3 className="heading3">{children}</h3>),

        [BLOCKS.HEADING_4]:(node, children) =>
        (<h4 className="heading4">{children}</h4>),

        [BLOCKS.HEADING_5]:(node, children) =>
        (<h5 className="heading5">{children}</h5>),

        [BLOCKS.HEADING_6]:(node, children) =>
        (<h6 className="heading6">{children}</h6>),

        [BLOCKS.UL_LIST]:(node, children) =>
        (<ul className="ul-list">{children}</ul>),

        [BLOCKS.OL_LIST]:(node, children) =>
        (<ol className="ol-list">{children}</ol>),
        
        [BLOCKS.EMBEDDED_ASSET]:(node, children) =>
        (<img className="terms-image" alt="update img" src={`https:${node.data.target.fields.file["en-US"].url}`}/>),
        [BLOCKS.PARAGRAPH]:(node, children) =>
        (<p className="terms-paragraph">{children}</p>),
        },
        renderMark: {
            [MARKS.CODE]: code => <code>{code}</code>,
            [MARKS.BOLD]: text => <b>{`${text}`} </b>,
        },
    }


    return <Layout>
        <Seo 
            title="Terms and conditions - Sogody" 
            description="Get informed on all the terms and conditions on visiting Sogody's website."
            url={pageUrl}
        />
        <div className="terms-banner">
            <Container> 
                <p className="banner-title">{bannerTitle}</p>
            </Container>
        </div>
        <div className="terms-cnt">
        <Container className="up-container"> 
            <div className="terms-content">
                {documentToReactComponents(cnt.json, options)
                    && (
                        <p>{documentToReactComponents(cnt.json, options)}</p>
                    )
                }
            </div>
        </Container>
        </div>
    </Layout>
}

export const query = graphql`
query GetLegalTerms{
    legalTerms: contentfulLegalTerms {
        bannerTitle
        termsContent {
        json
        }
    }
}
`


